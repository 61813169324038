import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  FaTrashAlt,
  FaCaretUp,
  FaCaretDown,
  FaCaretRight,
} from "react-icons/fa";
import { API } from "aws-amplify";
import moment from "moment";
import { without, sort, prop, ascend } from "ramda";

import useInterval from "./hooks/useInterval";

const { SearchBar } = Search;

const getSortFromUrl = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.hash.split("?")[1]);
  const sortField = params.get("sort");
  const sortOrder = params.get("order");

  if (!sortField || !sortOrder) {
    return [
      {
        // default sort if not in URL
        dataField: "date",
        order: "desc",
      },
    ];
  }

  return [
    {
      dataField: sortField,
      order: sortOrder,
    },
  ];
};

const updateUrl = (field, order) => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.hash.split("?")[1]);

  params.set("sort", field);
  params.set("order", order);

  url.hash = url.hash.split("?")[0] + "?" + params.toString();
  window.history.replaceState({}, "", url.toString());
};

const ComparisonTable = ({
  keyField,
  data,
  columns,
  defaultSorted,
  selectRow,
  selected,
  handleDelete,
}) => (
  <ToolkitProvider keyField={keyField} data={data} columns={columns} search>
    {(props) => (
      <div>
        <SearchBar {...props.searchProps} />
        {selected.length > 0 && (
          <span className="float-right">
            <Button
              variant="secondary"
              disabled={selected.length === 0}
              onClick={handleDelete}
            >
              <FaTrashAlt /> Delete selected
            </Button>
          </span>
        )}
        <BootstrapTable
          bootstrap4
          striped
          bordered={false}
          defaultSorted={defaultSorted}
          selectRow={selectRow}
          {...props.baseProps}
        />
      </div>
    )}
  </ToolkitProvider>
);

const Comparisons = () => {
  const [comparisons, setComparisons] = useState([]);
  const [selected, setSelected] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const initialSort = getSortFromUrl();
  const loadComparisons = async () => {
    const newComparisons = await API.get("mgp", "/comparisons");
    setComparisons(sort(ascend(prop("date")), newComparisons));
  };
  const handleDelete = async (event) => {
    event.preventDefault();
    setShowConfirmDelete(true);
  };
  const handleConfirmCancel = () => {
    setShowConfirmDelete(false);
  };
  const handleConfirm = async (event) => {
    event.preventDefault();
    try {
      for (const id of selected) {
        await API.del("mgp", `/comparisons/${id}`);
      }
      setSelected([]);
      await loadComparisons();
    } catch (err) {
      alert(err.message);
    }
    setShowConfirmDelete(false);
  };
  useEffect(() => {
    (async () => {
      try {
        await loadComparisons();
      } catch (err) {
        alert(err);
      }
    })();
  }, []);
  const sortCaret = (order) =>
    order === "asc" ? (
      <FaCaretUp />
    ) : order === "desc" ? (
      <FaCaretDown />
    ) : (
      <FaCaretRight style={{ visibility: "hidden" }} />
    );
  useInterval(() => {
    // refresh sample list if there are samples in 'Processing' state
    (async () => {
      try {
        if (
          comparisons &&
          comparisons.find((sample) => sample.status === "Processing")
        ) {
          await loadComparisons();
        }
      } catch (err) {
        alert(err);
      }
    })();
  }, 10000);
  return (
    <>
      <Modal show={showConfirmDelete} backdrop="static">
        <Modal.Header>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Delete selected {selected.length}{" "}
            {selected.length === 1 ? "comparison" : "comparisons"}?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleConfirmCancel}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirm}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <h3>Comparisons</h3>
      <ComparisonTable
        keyField="id"
        data={comparisons}
        columns={[
          {
            dataField: "name",
            text: "Name",
            sort: true,
            onSort: (field, order) => updateUrl(field, order),
            sortCaret,
            formatter: (name, sample) =>
              sample.status === "Processing" ? (
                name
              ) : (
                <a href={`#/comparisons/${sample.id}`}>{name}</a>
              ),
          },
          {
            dataField: "samples",
            text: "Samples",
            sort: true,
            sortCaret,
            onSort: (field, order) => updateUrl(field, order),
            formatter: (samples) =>
              samples &&
              samples.length >= 0 && (
                <span
                  title={
                    samples.length <= 10
                      ? samples.join(", ")
                      : samples.slice(0, 10).join(", ") + ", ..."
                  }
                >
                  {samples.length}
                </span>
              ),
          },
          {
            dataField: "date",
            text: "Date",
            sort: true,
            sortCaret,
            onSort: (field, order) => updateUrl(field, order),
            formatter: (date) =>
              date && moment(date).format("YYYY-MM-DD HH:mm"),
            filterValue: (date) =>
              date && moment(date).format("YYYY-MM-DD HH:mm"),
          },
        ]}
        defaultSorted={initialSort}
        selectRow={{
          mode: "checkbox",
          hideSelectAll: true,
          selected: selected,
          onSelect: (row, isSelect) => {
            if (isSelect) {
              setSelected(selected.concat(row.id));
            } else {
              setSelected(without([row.id], selected));
            }
          },
        }}
        selected={selected}
        handleDelete={handleDelete}
      />
      <p>
        Create a new comparison by selecting two or more{" "}
        <a href="#/samples">samples</a>.
      </p>
    </>
  );
};

export default Comparisons;
