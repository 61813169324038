import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { API } from "aws-amplify";
import "./Sample.css";

const getComparison = async (id) => await API.get("mgp", `/comparisons/${id}`);

const Comparison = (props) => {
  const { id } = useParams();
  const [comparison, setComparison] = useState({});
  const fetchComparison = async (id) => setComparison(await getComparison(id));
  useEffect(() => {
    (async () => {
      // fetch data
      try {
        await Promise.all([fetchComparison(id)]);
      } catch (error) {
        console.log(error);
        alert(error);
      }
    })();
  }, [id]);
  return (
    <>
      <h3>{comparison.name}</h3>
      <Table>
        <thead>
          <tr>
            <th>Sample</th>
          </tr>
        </thead>
        <tbody>
          {comparison.samples &&
            comparison.samples.length > 0 &&
            comparison.samples.map((sample) => (
              <tr key={sample}>
                <td>
                  <a href={`/#/samples/${sample}`}>{sample}</a>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default Comparison;
